var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c("h4", [
            _c("i", { staticClass: "fa fa-search" }),
            _vm._v(" Search Invoice")
          ]),
          _c("hr"),
          _c(
            "b-collapse",
            {
              attrs: { id: "searchCollapse" },
              model: {
                value: _vm.searchContainer,
                callback: function($$v) {
                  _vm.searchContainer = $$v
                },
                expression: "searchContainer"
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Invoice Tracking Number")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "tracking",
                              placeholder: "Tracking Number"
                            },
                            model: {
                              value: _vm.invnum,
                              callback: function($$v) {
                                _vm.invnum = $$v
                              },
                              expression: "invnum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Project")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.projectidSearch,
                            expression: "projectidSearch"
                          }
                        ],
                        staticClass: "select-account form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.projectidSearch = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "", selected: "" } }, [
                          _vm._v("Select Project")
                        ]),
                        _vm._l(_vm.projectData, function(option) {
                          return _c(
                            "option",
                            { domProps: { value: option.projectid } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(option.projectname) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Vendor Company Name")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "vendor",
                              placeholder: "Vendor Company Name"
                            },
                            model: {
                              value: _vm.vendor_company_name,
                              callback: function($$v) {
                                _vm.vendor_company_name = $$v
                              },
                              expression: "vendor_company_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("AFE / PO Number")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "afe",
                              placeholder: "AFE Number"
                            },
                            model: {
                              value: _vm.afe,
                              callback: function($$v) {
                                _vm.afe = $$v
                              },
                              expression: "afe"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #1 / Major")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc1",
                              placeholder: "Cost Code 1"
                            },
                            model: {
                              value: _vm.cc1,
                              callback: function($$v) {
                                _vm.cc1 = $$v
                              },
                              expression: "cc1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #2 / Minor")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc2",
                              placeholder: "Cost Code 2"
                            },
                            model: {
                              value: _vm.cc2,
                              callback: function($$v) {
                                _vm.cc2 = $$v
                              },
                              expression: "cc2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #3 / Description")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc3",
                              placeholder: "Cost Code 3"
                            },
                            model: {
                              value: _vm.cc3,
                              callback: function($$v) {
                                _vm.cc3 = $$v
                              },
                              expression: "cc3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Invoice Starting Date - Today")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("datepicker", {
                            staticClass: "date-picker rt-datepicker",
                            attrs: { placeholder: "Start Date" },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-3",
                              attrs: { variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [
                              _vm._v("\n              Search "),
                              _c("i", { staticClass: "fa fa-search" })
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.clear()
                                }
                              }
                            },
                            [
                              _vm._v("\n              Clear "),
                              _c("i", { staticClass: "fa fa-times" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-btn",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.searchCollapse",
                          modifiers: { searchCollapse: true }
                        }
                      ],
                      staticClass: "mt-1 btn-blue"
                    },
                    [
                      _c("span", { staticClass: "when-opened" }, [
                        _vm._v("Close Advanced Search "),
                        _c("i", { staticClass: "fa fa-chevron-up" })
                      ]),
                      _c("span", { staticClass: "when-closed" }, [
                        _vm._v("Open Advanced Search "),
                        _c("i", { staticClass: "fa fa-chevron-down" })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("h4", [
            _c("i", { staticClass: "nav-icon icon-layers" }),
            _vm._v(" Invoices")
          ]),
          _c("hr"),
          _c("div", { staticClass: "row mb-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4 col-9" },
              [
                _c("h5", [_vm._v("Unprocessed / Processed")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: { label: "type", options: _vm.typeOptions },
                  on: {
                    input: function($event) {
                      return _vm.loadInvoices()
                    }
                  },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-9" },
              [
                _c("h5", [_vm._v("Select Project Type")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: { label: "type", options: _vm.projectTypesOption },
                  on: {
                    input: function($event) {
                      return _vm.filterProject(true)
                    }
                  },
                  model: {
                    value: _vm.projectType,
                    callback: function($$v) {
                      _vm.projectType = $$v
                    },
                    expression: "projectType"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-3 col-9" },
              [
                _c("h5", [_vm._v("Select Project")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: { label: "projectname", options: _vm.projectOptions },
                  on: {
                    input: function($event) {
                      return _vm.loadInvoices()
                    }
                  },
                  model: {
                    value: _vm.selectedProject,
                    callback: function($$v) {
                      _vm.selectedProject = $$v
                    },
                    expression: "selectedProject"
                  }
                })
              ],
              1
            ),
            _vm.type
              ? _c("div", { staticClass: "col-md-1 col-3" }, [
                  _c("h5", [_vm._v(" ")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.loadInvoices()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-sync-alt" })]
                  )
                ])
              : _vm._e()
          ]),
          _vm.type.type === "Pending By You"
            ? _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "my-1 btn-sm btn-success mr-3",
                      on: {
                        click: function($event) {
                          return _vm.bulkMark(1)
                        }
                      }
                    },
                    [
                      _vm._v("\n        Mark Selected As Approved "),
                      _c("i", { staticClass: "fa fa-check" })
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "my-1 btn-sm btn-danger mr-3",
                      on: {
                        click: function($event) {
                          return _vm.bulkMark(2)
                        }
                      }
                    },
                    [
                      _vm._v("\n        Mark Selected As Rejected "),
                      _c("i", { staticClass: "fa fa-times" })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-client-table", {
            ref: "invoiceTable",
            staticClass:
              "manager-status-table table-responsivemanager-table manager-table",
            attrs: {
              name: "invoiceTable",
              columns: _vm.columns,
              data: _vm.invoiceData,
              options: _vm.options,
              theme: _vm.theme,
              id: "dataTable"
            },
            scopedSlots: _vm._u([
              {
                key: "total",
                fn: function(props) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatPrice(props.row.total)) +
                        "\n      "
                    )
                  ])
                }
              },
              {
                key: "quickpay_total",
                fn: function(props) {
                  return _c("div", {}, [
                    props.row.quickpay_total > 0 &&
                    _vm.$moment(props.row.endtday) > _vm.$moment()
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatPrice(props.row.quickpay_total)
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "check",
                fn: function(props) {
                  return _c("div", {}, [
                    _vm.type.type === "Unprocessed" ||
                    _vm.type.type === "Pending By You"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkedRows,
                              expression: "checkedRows"
                            }
                          ],
                          staticClass: "rt-checkbox-table",
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: props.row,
                            checked: Array.isArray(_vm.checkedRows)
                              ? _vm._i(_vm.checkedRows, props.row) > -1
                              : _vm.checkedRows
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.checkedRows,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = props.row,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedRows = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedRows = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedRows = $$c
                              }
                            }
                          }
                        })
                      : _vm._e()
                  ])
                }
              },
              {
                key: "inv_passed",
                fn: function(props) {
                  return _c("div", {}, [
                    props.row.accountant_paid === 0
                      ? _c("div", [
                          props.row.inv_passed === 9
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By WSS (Submit Again)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 8
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-warning btn-pm" },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(
                                      " Pending By Project Manager\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 7
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 6
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-warning" },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(
                                      " Pending By Management\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 5
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Project Manager (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 0
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-warning" },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(" Pending By WSS\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 2
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-blue" },
                                  [
                                    _c("i", { staticClass: "fa fa-save" }),
                                    _vm._v(" Draft\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 11
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 13
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-sm btn-warning btn-management"
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(" Pending By\n              "),
                                    _vm.authority <= 1
                                      ? _c("span", [_vm._v("Higher ")])
                                      : _vm._e(),
                                    _vm._v("Management\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 14
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Management (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 21
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 23
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-sm btn-warning btn-management"
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(" Pending By\n              "),
                                    _vm.authority <= 2
                                      ? _c("span", [_vm._v("Higher ")])
                                      : _vm._e(),
                                    _vm._v("Management\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 24
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Management (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 31
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 33
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-sm btn-warning btn-management"
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(" Pending By\n              "),
                                    _vm.authority <= 3
                                      ? _c("span", [_vm._v("Higher ")])
                                      : _vm._e(),
                                    _vm._v("Management\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 34
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Management (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 41
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 43
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-sm btn-warning btn-management"
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-question" }),
                                    _vm._v(" Pending By\n              "),
                                    _vm.authority <= 4
                                      ? _c("span", [_vm._v("Higher ")])
                                      : _vm._e(),
                                    _vm._v("Management\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 44
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Management (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 51
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-success" },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Ready For Payment\n            ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          props.row.inv_passed === 54
                            ? _c("div", [
                                _c(
                                  "button",
                                  { staticClass: "btn-sm btn-danger" },
                                  [
                                    _c("i", { staticClass: "fa fa-times" }),
                                    _vm._v(
                                      " Rejected By Management (Pending\n              Vendor)\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    props.row.accountant_paid === 1
                      ? _c("div", [
                          _c("button", { staticClass: "btn-sm btn-success" }, [
                            _c("i", { staticClass: "fa fa-check" }),
                            _vm._v(" Paid\n          ")
                          ])
                        ])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "creation_date",
                fn: function(props) {
                  return _c("div", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.creation_date,
                            "MM/DD/YYYY hh:mm A"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                }
              },
              {
                key: "vfname",
                fn: function(props) {
                  return _c("div", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(props.row.vfname) +
                        " " +
                        _vm._s(props.row.vlname) +
                        "\n      "
                    )
                  ])
                }
              },
              {
                key: "invoiceid",
                fn: function(props) {
                  return _c("div", {}, [
                    (props.row.inv_passed == 13 && _vm.authority == 1) ||
                    (props.row.inv_passed == 23 && _vm.authority <= 2) ||
                    (props.row.inv_passed == 33 && _vm.authority <= 3) ||
                    (props.row.inv_passed == 43 && _vm.authority <= 4) ||
                    props.row.accountant_paid == 1 ||
                    props.row.inv_passed == 11 ||
                    props.row.inv_passed == 21 ||
                    props.row.inv_passed == 31 ||
                    props.row.inv_passed == 41 ||
                    props.row.inv_passed == 51 ||
                    props.row.inv_passed == 7 ||
                    props.row.inv_passed == 14 ||
                    props.row.inv_passed == 24 ||
                    props.row.inv_passed == 34 ||
                    props.row.inv_passed == 44 ||
                    props.row.inv_passed == 54
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.setTheInvoice(props.row, 0)
                              }
                            }
                          },
                          [_vm._v(_vm._s(props.row.invnum) + "\n        ")]
                        )
                      : _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.setTheInvoice(props.row, 1)
                              }
                            }
                          },
                          [_vm._v(_vm._s(props.row.invnum))]
                        ),
                    _c("p", { staticClass: "mb-0 d-sm-block d-md-none" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("moment")(
                              props.row.creation_date,
                              "MM-DD-YYYY"
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ])
                }
              },
              {
                key: "actions",
                fn: function(props) {
                  return _c("div", {}, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.history",
                            modifiers: { history: true }
                          }
                        ],
                        staticClass: "btn white btn-sm btn-secondary",
                        on: {
                          click: function($event) {
                            return _vm.sendHistory(props.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-history" })]
                    )
                  ])
                }
              }
            ])
          }),
          _c(
            "b-modal",
            {
              ref: "history",
              attrs: {
                "ok-only": "",
                id: "history",
                title: "Invoice #" + _vm.history.invnum,
                "title-tag": "h2",
                centered: "",
                size: "xl"
              }
            },
            [_c("InvoiceHistory", { attrs: { history: _vm.history } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }