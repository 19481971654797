<template>
  <div class="animated fadeIn">
    <b-card>
      <h4><i class="fa fa-search"></i> Search Invoice</h4>
      <hr />
      <b-collapse v-model="searchContainer" id="searchCollapse">
        <b-row class="mb-2">
          <b-col md="4">
            <label for="">Invoice Tracking Number</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="tracking"
                v-model="invnum"
                class="form-control"
                placeholder="Tracking Number"
              />
            </b-input-group>
          </b-col>
          <div class="col-md-4">
            <label for="">Project</label>
            <select
              class="select-account form-control"
              v-model="projectidSearch"
            >
              <option value="" selected>Select Project</option>
              <option
                v-for="option in projectData"
                v-bind:value="option.projectid"
              >
                {{ option.projectname }}
              </option>
            </select>
          </div>
          <b-col md="4">
            <label for="">Vendor Company Name</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="vendor"
                v-model="vendor_company_name"
                class="form-control"
                placeholder="Vendor Company Name"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <label for="">AFE / PO Number</label>
            <b-input-group class="mb-2">
              <b-input
                type="text"
                name="afe"
                v-model="afe"
                class="form-control"
                placeholder="AFE Number"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #1 / Major</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc1"
                v-model="cc1"
                class="form-control"
                placeholder="Cost Code 1"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #2 / Minor</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc2"
                v-model="cc2"
                class="form-control"
                placeholder="Cost Code 2"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #3 / Description</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc3"
                v-model="cc3"
                class="form-control"
                placeholder="Cost Code 3"
              />
            </b-input-group>
          </b-col>
          <b-col md="4">
            <label for="">Invoice Starting Date - Today</label>
            <b-input-group class="mb-2">
              <datepicker
                class="date-picker rt-datepicker"
                v-model="date"
                placeholder="Start Date"
              ></datepicker>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <label for="">Search</label>
            <b-input-group class="mb-2">
              <b-button @click="search()" variant="success" class="mr-3">
                Search <i class="fa fa-search"></i>
              </b-button>
              <b-button @click="clear()" variant="danger">
                Clear <i class="fa fa-times"></i>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-collapse>
      <b-row>
        <b-col class="col-md-12">
          <b-btn v-b-toggle.searchCollapse class="mt-1 btn-blue">
            <span class="when-opened"
              >Close Advanced Search <i class="fa fa-chevron-up"></i
            ></span>
            <span class="when-closed"
              >Open Advanced Search <i class="fa fa-chevron-down"></i
            ></span>
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4><i class="nav-icon icon-layers"></i> Invoices</h4>
      <hr />
      <div class="row mb-3">
        <div class="col-md-4 col-9">
          <h5>Unprocessed / Processed</h5>
          <v-select
            class="project-select"
            v-model="type"
            label="type"
            :options="typeOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div class="col-md-3 col-9">
          <h5>Select Project Type</h5>
          <v-select
            class="project-select"
            v-model="projectType"
            label="type"
            :options="projectTypesOption"
            @input="filterProject(true)"
          ></v-select>
        </div>
        <div class="col-md-3 col-9">
          <h5>Select Project</h5>
          <v-select
            class="project-select"
            v-model="selectedProject"
            label="projectname"
            :options="projectOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
          <div v-if="type" class="col-md-1 col-3">
          <h5>&nbsp;</h5>
          <button @click="loadInvoices()" class="btn btn-primary">
            <i class="fa fa-sync-alt"></i>
          </button>
        </div>
      </div>
      <div class="mb-3" v-if="type.type === 'Pending By You'">
        <b-button @click="bulkMark(1)" class="my-1 btn-sm btn-success mr-3">
          Mark Selected As Approved <i class="fa fa-check"></i>
        </b-button>
        <b-button @click="bulkMark(2)" class="my-1 btn-sm btn-danger mr-3">
          Mark Selected As Rejected <i class="fa fa-times"></i>
        </b-button>
      </div>

      <v-client-table
        name="invoiceTable"
        ref="invoiceTable"
        class="manager-status-table table-responsivemanager-table manager-table"
        :columns="columns"
        :data="invoiceData"
        :options="options"
        :theme="theme"
        id="dataTable"
      >
        <span slot="total" slot-scope="props">
          {{ formatPrice(props.row.total) }}
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span
            v-if="
              props.row.quickpay_total > 0 &&
              $moment(props.row.endtday) > $moment()
            "
          >
            {{ formatPrice(props.row.quickpay_total) }}
          </span>
        </div>

        <div slot="check" slot-scope="props">
          <input
            v-if="type.type === 'Unprocessed' || type.type === 'Pending By You'"
            type="checkbox"
            :value="props.row"
            class="rt-checkbox-table"
            v-model="checkedRows"
          />
        </div>

        <div slot="inv_passed" slot-scope="props">
          <div v-if="props.row.accountant_paid === 0">
            <div v-if="props.row.inv_passed === 9">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By WSS (Submit Again)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 8">
              <button class="btn-sm btn-warning btn-pm">
                <i class="fa fa-question"></i> Pending By Project Manager
              </button>
            </div>

            <div v-if="props.row.inv_passed === 7">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 6">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 5">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Project Manager (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 0">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By WSS
              </button>
            </div>

            <div v-if="props.row.inv_passed === 2">
              <button class="btn-sm btn-blue">
                <i class="fa fa-save"></i> Draft
              </button>
            </div>

            <div v-if="props.row.inv_passed === 11">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 13">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By
                <span v-if="authority <= 1">Higher </span>Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 14">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 21">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 23">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By
                <span v-if="authority <= 2">Higher </span>Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 24">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 31">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 33">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By
                <span v-if="authority <= 3">Higher </span>Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 34">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 41">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 43">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By
                <span v-if="authority <= 4">Higher </span>Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 44">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 51">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> Ready For Payment
              </button>
            </div>

            <div v-if="props.row.inv_passed === 54">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success">
              <i class="fa fa-check"></i> Paid
            </button>
          </div>
        </div>
        <div slot="creation_date" slot-scope="props">
          {{ props.row.creation_date | moment("MM/DD/YYYY hh:mm A") }}
        </div>
        <div slot="vfname" slot-scope="props">
          {{ props.row.vfname }} {{ props.row.vlname }}
        </div>

        <div slot="invoiceid" slot-scope="props">
          <a
            href="javascript:void(0)"
            @click="setTheInvoice(props.row, 0)"
            v-if="
              (props.row.inv_passed == 13 && authority == 1) ||
              (props.row.inv_passed == 23 && authority <= 2) ||
              (props.row.inv_passed == 33 && authority <= 3) ||
              (props.row.inv_passed == 43 && authority <= 4) ||
              props.row.accountant_paid == 1 ||
              props.row.inv_passed == 11 ||
              props.row.inv_passed == 21 ||
              props.row.inv_passed == 31 ||
              props.row.inv_passed == 41 ||
              props.row.inv_passed == 51 ||
              props.row.inv_passed == 7 ||
              props.row.inv_passed == 14 ||
              props.row.inv_passed == 24 ||
              props.row.inv_passed == 34 ||
              props.row.inv_passed == 44 ||
              props.row.inv_passed == 54
            "
            >{{ props.row.invnum }}
          </a>

          <a
            v-else
            href="javascript:void(0)"
            @click="setTheInvoice(props.row, 1)"
            >{{ props.row.invnum }}</a
          >
          <p class="mb-0 d-sm-block d-md-none">
            {{ props.row.creation_date | moment("MM-DD-YYYY") }}
          </p>
        </div>

        <div slot="actions" slot-scope="props">
          <button
            v-b-modal.history
            @click="sendHistory(props.row)"
            class="btn white btn-sm btn-secondary"
          >
            <i class="fa fa-history"></i>
          </button>
        </div>
      </v-client-table>

      <b-modal
        ok-only
        id="history"
        ref="history"
        :title="'Invoice #' + history.invnum"
        title-tag="h2"
        centered
        size="xl"
      >
        <InvoiceHistory v-bind:history="history"></InvoiceHistory>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable } from "vue-tables-2";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import InvoiceHistory from "./../invoice/InvoiceHistory";
Vue.use(ClientTable);

export default {
  name: "manager",
  components: {
    ClientTable,
    Datepicker,
    InvoiceHistory,
  },
  data() {
    return {
      columns: [
        "check",
        "inv_passed",
        "invoiceid",
        "ticketnum",
        "vendor_invoice_number",
        "vcn",
        "vfname",
        "creation_date",
        "total",
        "quickpay_total",
        "actions",
      ],
      invoiceData: [],
      today: new Date(),
      options: {
        headings: {
          check: "",
          inv_passed: "Status",
          invoiceid: "Invoice #",
          creation_date: "Submitted Date",
          amount: "Amount",
          vcn: "Company Name",
          vfname: "Vendor Name",
          quickpay_total: "Quickpay Total",
          ticketnum: "Field Ticket #",
          vendor_invoice_number: "Vendor Invoice #",
        },
        saveState: true,
        useVuex: true,
        perPage: 40,
        sortable: [
          "inv_passed",
          "invoiceid",
          "vcn",
          "vfname",
          "creation_date",
          "total",
          "quickpay_total",
        ],
        filterable: [
          "inv_passed",
          "invoiceid",
          "invnum",
          "ticketnum",
          "vendor_invoice_number",
          "vcn",
          "vfname",
          "vlname",
          "creation_date",
          "total",
          "quickpay_total",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      searchContainer: false,
      cc: "",
      history: [],
      tracking: "",
      vendor: "",
      type: {
        type: "Unprocessed By Management",
      },
      typeOptions: [
        {
          type: "All",
        },
        {
          type: "Pending By You",
        },
        {
          type: "Unprocessed By Management",
        },
        {
          type: "Processed",
        },
      ],
      projectData: [],
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      projectid: 0,
      checkedRows: [],
      afe: "",
      cc1: "",
      cc2: "",
      cc3: "",
      invnum: "",
      date: "",
      vendor_company_name: "",
      projectidSearch: "",
      windowWidth: 0,
      windowHeight: 0,
      set: 0,
      authority: 0,
      usersName: "",
      projectType: {},
      selectedProject: "",
      allInvoices: [],
      projectOptions: [],
      projectTypesOption: [{
        type: 'Operations',
        val: 0
      }, {
        type: 'Resource',
        val: 1
      },{
        type: 'All',
        val: 2
      }]
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setTheInvoice(props, setInvoice) {
      this.setInvoice(props, 0, this.$refs.invoiceTable);
      this.$router.push({
        name: "Manager Invoice",
        path: "/manager/invoice",
        params: { invoice: props, set: setInvoice, role: "Manager" },
      });
    },
    filterProject(history){
      if(this.projectType.type !== "All") {
      const projectTypeVal = this.projectType.type === 'Operations' ?  0 : 1;
      this.projectOptions = [{projectid: 0, projectname: "All"}].concat(this.projectData.filter((x) => (x.acc_project == projectTypeVal)));
      } else {
       this.selectedProject = this.projectData[0];
        this.projectOptions = [{projectid: 0, projectname: "All"}].concat(this.projectData);
      }
      if(history) {
        this.selectedProject = this.projectOptions[0];
      }
    },
    refreshinvoices() {
    this.allInvoices.length = 0;  
    this.$http
      .get("/manager/get/unprocessed_invoices/").then(res => {
        this.$http
        .get("/manager/get/processed_invoices/").then(val => {
        this.allInvoices.push(...res.data.result, ...val.data.result);
        this.loadInvoices();
        }).catch(err => {
          console.log(err,"err");
        });
    }).catch(err => console.log(err,"err"));
    },
    loadInvoices() {
      this.$session.set("type", this.type.type);
      this.$session.set("project_type", this.projectType);
      this.$session.set("selected_project", this.selectedProject);
      this.checkedRows = [];
      if (this.type.type === "Unprocessed By Management") {
        if(this.projectType.type !== 'All') {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
            x.projectid === this.selectedProject.projectid && [6,13,23,33,43].includes(x.inv_passed));
          } else {
             this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
             [6,13,23,33,43].includes(x.inv_passed));
          }
        } else {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.projectid === this.selectedProject.projectid && 
            [6,13,23,33,43].includes(x.inv_passed));
          } else {
             this.invoiceData = this.allInvoices.filter(x =>
             [6,13,23,33,43].includes(x.inv_passed));
          }
        }
      }
      if (this.type.type === "Pending By You") {
        let inv_passed = 0;
            if (this.authority == 1) {
             inv_passed = 6;
            }
            if (this.authority == 2) {
              inv_passed = 13;
            }
            if (this.authority == 3) {
              inv_passed = 23;
            }
            if (this.authority == 4) {
              inv_passed = 33;
            }
            if (this.authority == 5) {
              inv_passed = 43;
            }
        if(this.projectType.type !== 'All') {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
            x.projectid === this.selectedProject.projectid && x.inv_passed === inv_passed);
          } else {
             this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
             x.inv_passed === inv_passed);
          }
        } else {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.projectid === this.selectedProject.projectid && 
            x.inv_passed === inv_passed);
          } else {
             this.invoiceData = this.allInvoices.filter(x =>
             x.inv_passed === inv_passed);
          }
        }     
      }
      if (this.type.type === "Processed") {
          if(this.projectType.type !== 'All') {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
            x.projectid === this.selectedProject.projectid && [7,11,14,21,24,31,34,41,44,51,54].includes(x.inv_passed));
          } else {
             this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
             [7,11,14,21,24,31,34,41,44,51,54].includes(x.inv_passed));
          }
        } else {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.projectid === this.selectedProject.projectid && 
            [7,11,14,21,24,31,34,41,44,51,54].includes(x.inv_passed));
          } else {
             this.invoiceData = this.allInvoices.filter(x =>
             [7,11,14,21,24,31,34,41,44,51,54].includes(x.inv_passed));
          }
        }
      }
      if (this.type.type === "All") {
           if(this.projectType.type !== 'All') {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val && 
            x.projectid === this.selectedProject.projectid);
          } else {
             this.invoiceData = this.allInvoices.filter(x => x.acc_project === this.projectType.val);
          }
        } else {
          if(this.selectedProject.projectid !== 0) {
            this.invoiceData = this.allInvoices.filter(x => x.projectid === this.selectedProject.projectid);
          } else {
             this.invoiceData = this.allInvoices;
          }
        }
      }
    },
    search() {
      this.projectid = 0;
      this.checkedRows = [];
      this.type = [];

      var date = "";
      if (this.date !== "") {
        date = this.$moment(this.date).format("YYYY-MM-DD");
      }
      let data = JSON.stringify({
        afe: this.afe,
        cc1: this.cc1,
        cc2: this.cc2,
        cc3: this.cc3,
        invnum: this.invnum,
        date: date,
        vendor_company_name: this.vendor_company_name,
        projectid: this.projectidSearch,
      });

      this.$axios
        .post("/manager/search/invoice", data)
        .then((response) => {
          var invoiceData = response.data.result;
          this.loadSearchInvoices(invoiceData);
        })
        .catch((error) => {});
    },
    loadSearchInvoices(invoiceData) {
      this.invoiceData = [];
      for (var i = 0; i < invoiceData.length; i++) {
        this.$http
          .get(
            "/manager/get/invoice/" + encodeURIComponent(invoiceData[i].invnum)
          )
          .then((response) => {
            this.error = true;
            var data = response.data.result;

            if (
              data.inv_passed !== 0 &&
              data.inv_passed !== 2 &&
              data.inv_passed !== 9 &&
              data.inv_passed !== 8 &&
              data.inv_passed !== 5
            ) {
              this.invoiceData.push(data);
            }
          })
          .catch((error) => {});
      }
    },
    bulkMark(status) {
      if (this.checkedRows.length === 0) {
        this.$toasted.show("No invoices selected.", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      for (var i = 0; i < this.checkedRows.length; i++) {
        let data = JSON.stringify({
          invoiceid: this.checkedRows[i].invoiceid,
          projectid: this.checkedRows[i].projectid,
          manager_stat: status,
          manager_note: this.usersName,
        });
        this.$http
          .post("/manager/edit/invoice/", data)
          .then((response) => {
            this.loadInvoices();
          })
          .catch((error) => {});
      }
      this.$toasted.show("Successfully Approved.", {
        type: "success",
        duration: "3000",
      });
      this.loadInvoices();
    },
    sendHistory(history) {
      this.history = history;
    },
    clear() {
      this.afe = "";
      this.cc1 = "";
      this.cc2 = "";
      this.cc3 = "";
      this.invnum = "";
      this.date = "";
      this.vendor_company_name = "";
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth < 991) {
        this.searchContainer = false;
      }
      if (this.windowWidth > 991) {
        this.searchContainer = false;
      }
    },
    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  },
  mounted() {
     this.$http
      .get("/manager/get/unprocessed_invoices/").then(res => {
        this.$http
        .get("/manager/get/processed_invoices/").then(val => {
        this.allInvoices.push(...res.data.result, ...val.data.result);
        }).catch(err => {
          console.log(err,"err");
        });
    }).catch(err => console.log(err,"err"));

    this.$http
      .get("manager/get/profile")
      .then((response) => {
        this.authority = response.data.authority;
        this.usersName = response.data.fname + " " + response.data.lname;
      })
      .catch((error) => {});
    this.$http
      .get("manager/get/projects")
      .then((response) => {
        this.projectData = response.data.result;
       if (this.$session.get("type")) {
          this.type.type = this.$session.get("type");
          this.projectType = this.$session.get("project_type");
          this.selectedProject = this.$session.get("selected_project");
          this.filterProject(false);
          this.loadInvoices();
        } else {
          this.type.type = "Pending By You";
          this.projectType = {type: 'Operations', val: 0};
          this.filterProject(true);
          this.loadInvoices();
        }
      })
      .catch((error) => {});
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
};
</script>
